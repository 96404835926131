import React, { Component } from 'react'
import Swipeable from 'react-swipeable'
import keydown, { Keys } from 'react-keydown'
import Measure from 'react-measure'
import classNames from 'classnames'
import { Preload } from 'react-preload'
import { navigate } from 'gatsby'
import { Link } from 'gatsby-plugin-intl-v4'
import { GlobalHead } from '../components/GlobalHead'
import { CSSTransition } from 'react-transition-group'
import "../styles.scss"


class Slideshow extends React.Component {

  constructor(props, location) {
    super(props)

    const { projectId, slideImages } = this.props.pageContext

    this.state = {
      slideId: this.props.location?.state?.index ? this.props.location.state.index : 0,
      dimensions: {
        width: -1,
        height: -1
      }
    }
    this.updateSlideId = (id) => {
      this.setState({
        slideId: id
      })
    }
  }

  @keydown('right', 'enter', 'space')
  nextSlide() {

    const { projectId, slideImages } = this.props.pageContext
    const nextSlideId = (this.state.slideId + 1) - slideImages.length * Math.floor((this.state.slideId + 1) / slideImages.length)

    this.updateSlideId(nextSlideId)
  }

  @keydown('left')
  previousSlide() {

    const { projectId, slideImages } = this.props.pageContext
    const previousSlideId = (this.state.slideId  - 1) - slideImages.length * Math.floor((this.state.slideId  - 1) / slideImages.length)

    this.updateSlideId(previousSlideId)
  }

  @keydown('esc')
  close() {
    const { projectId } = this.props.pageContext
    navigate(`/project/${projectId}`)
  }

  swipeHandler(e, deltaX, deltaY, isFlick, velocity) {
    if (Math.abs(deltaX) > 20) {
      if (deltaX > 0) {
        this.nextSlide()
      } else {
        this.previousSlide()
      }
    }
  }

  render() {

    const { projectId, slideImages} = this.props.pageContext
    const slide = slideImages[this.state.slideId]

    const style = {
      backgroundImage: 'url(' + slide.url + '?w=2400&h=2400&fm=jpg&q=80)'
    }

    const { width, height } = this.state.dimensions

    const classes = classNames({'slidefade-animates': true, 'slideshow__image': true, 'slideshow__image__small-image': height > slide.height && width > slide.width})

    return (
      <Swipeable className={'slideshow'} onSwiped={(e, deltaX, deltaY, isFlick, velocity) => { this.swipeHandler(e, deltaX, deltaY, isFlick, velocity) }}>

          <div className={'slideshow__navigation'}>
            <div className={'slideshow__navigation-index'} onClick={() => this.nextSlide()}>
              <span className={'slidefade-animates'}>{this.state.slideId + 1}</span>
              <span>/</span>
              <span>{slideImages.length}</span>
            </div>
            <div className={'slideshow__navigation-buttons'} />
            <Link to={'/project/' + this.props.pageContext.projectId} className={'slideshow__navigation-close-button'}>
              &#10005;
            </Link>
          </div>

        <Measure bounds onResize={(rect) => { this.setState({ dimensions: rect.bounds }) } }>
          {({ measureRef }) =>
            <CSSTransition
              transitionName='slidefade'
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}>
              <div key={this.state.slideId} ref={measureRef} className={classes} style={style} onClick={() => this.nextSlide()} />
            </CSSTransition>
          }
        </Measure>
        <Preload
          images={slideImages.map((i) => i.url + '?w=2400&h=2400&fm=jpg&q=80')}
          autoResolveDelay={3000}
          resolveOnError={false}
          mountChildren={false}/>
      </Swipeable>
    )
  }
}

export default Slideshow

export function Head() {
  return (
    <GlobalHead />
  )
}
